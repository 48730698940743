<template>
  <v-container
    fluid
    class="mcv-login ma-0 pa-0"
    style="height:100%;"
    :style="{
      backgroundImage: 'url(/imgs/background_login.jpg)',
      backgroundSize: '100% 100%',
    }"
  >
    <v-row class="ma-0 mcv-login-row"  justify="center">
      <v-col class="ma-0 pa-0" align="center">
        <div
          style=" position: relative;background: rgba(76, 175, 80, 0);color: white;"
        >
          <div>
            <v-card flat class="mcv-login-card">
              <v-card-title>
                <div style="width: 100%;">
                  <v-img src="/imgs/mcvlogo128.png" style="width: 164px;"></v-img>
                </div>
              </v-card-title>
              <v-expand-transition>
                <v-card-subtitle
                  class="ma-0 pa-0"
                  v-show="showErrorInForm"
                  transition="fade-transition"
                >
                  <div class="d-block error white--text text-center">
                    {{ errForm.message }}
                  </div>
                </v-card-subtitle>
              </v-expand-transition>
              <v-card-text class="pa-0 ma-0">
                <div
                  v-if="1 == 2"
                  style="width:100%;color:#264B77;font-size:28px;font-weight:500;text-align:center;"
                >
                  Login
                </div>
                <v-form
                  class="pa-0 ma-0"
                  id="loginUserForm"
                  ref="loginUserForm"
                  v-model="formValid"
                >
                  <v-text-field
                    outlined
                    class="loginTextFieldClass my-2"
                    v-model="email"
                    label="Email *"
                    :rules="[rules.required, rules.emailRules]"
                    prepend-inner-icon="mdi-account"
                    style="font-size: 16px"
                    hint=""
                    hide-details
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="pa-0 ma-0 mt-2">
                <v-spacer></v-spacer>
                <v-btn
                  :loading="loadingSigninBtn"
                  color="white"
                  text
                  @click="loginUser"
                  class="px-4"
                  style="height: 40px;font-size: 18px;text-transform: capitalize;background-color: #264B77;font-weight: 200;"
                  >Send</v-btn
                >
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbarApp"
      :timeout="5000"
      :color="colorSnackBar"
      :top="'top'"
      style="top: 8px;"
    >
      <div style="width: 100%; text-align:center;font-size: 14px;">
        {{ messageSnackbarApp }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
// const moment = require("moment");
export default {
  props: ["login"],
  data() {
    return {
      loadingSigninBtn: false,
      snackbarApp: false,
      colorSnackBar: "info",
      messageSnackbarApp: "",
      email: "",
      password: "",
      showPassword: false,
      show: false,
      formValid: true,
      errForm: {
        status: false,
        message: "",
      },
      rules: {
        required: (value) => !!value || "(*)",
        emailRules: (v) => v.length >= 3 || "Min 3 characters",
        minPassword: (v) => v.length >= 6 || "Min 6 characters",
      },
    };
  },
  watch: {
    show(val) {
      if (!val) {
        this.resetValidate();
      }
    },
  },
  computed: {
    showErrorInForm() {
      return this.errForm.status;
    },
  },
  methods: {
    loginUser() {
      if (!this.isValidate()) return;
      else {
        this.callRequestGetPassword();
      }
    },
    callRequestGetPassword() {
      this.loadingSigninBtn = true;
      let self = this;
      let reqData = JSON.stringify({
        email: this.email
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      self.axios
        .post(this.$root.apiUser + "/users/forgetPassword", reqData, config)
        .then((response) => {
          console.log(response.data)
          if (response.data.status == "OK") {
            // self.$emit("loginDone");
            alert("Mật khẩu mới đã được chuyển tới Email!")
            self.$router.push({name: "Login"});
          } else {
            this.showMessageApp( "Lỗi! "+ response.data.message, "error");
            // alert(response.data.message)
          }
          this.loadingSigninBtn = false;
        })
        .catch(() => {
          this.loadingSigninBtn = false;
          this.showMessageApp("Internal Server Error!", "error");
        });
    },
    resetValidate() {
      this.$refs.loginUserForm.reset();
    },
    isValidate() {
      let result = true;
      if (!this.$refs.loginUserForm.validate()) {
        this.errForm.status = true;
        setTimeout(() => {
          this.errForm.status = false;
        }, 5000);
        this.errForm.message = "Not Valid Input!";
        result = false;
      }
      return result;
    },
    showMessageApp(message, type) {
      this.snackbarApp = true;
      this.messageSnackbarApp = message;
      this.colorSnackBar = !type ? "info" : type;
    },
  },
};
</script>

<style>
#loginUserForm div label {
  /* font-size: 1.1vw;
    height: 1.4vw;
    line-height: 1.4vw; */
}
#loginUserForm div.loginTextFieldClass {
  min-height: 60px;
  line-height: 60px;
}
#loginUserForm div.v-messages__message {
  font-size: 14px;
  min-height: 40px;
  line-height: 40px;
}
/** Mobile */
@media only screen and (max-width: 959px) {
  .mcv-login-row {
    padding-top: 7vh !important;
  }
  .mcv-login-card {
    padding: 10px 30px 30px 30px;
    background-color: white;
    width: 90vw;
    max-width: 350px;
  }
}

/** Desktop */
@media only screen and (min-width: 960px) {
  .mcv-login-row {
    padding-top: 14vh !important;
  }
  .mcv-login-card {
    padding: 10px 40px 30px 40px;
    background-color: white;
    min-width: 30vw;
    max-width: 30vw;
  }
}
</style>
